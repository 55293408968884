<template>
  <div class="card p-1">
    <div class="custom-search">
      <div class="title head">
        <p class="h2" style="color: #558cef">รายงานแยกตามกลุ่มงาน</p>
      </div>
      <hr />
      <!-- advance search input -->
      <div class="custom-search row justify-content-start pl-1">
        <!-- <div>
          <label class="mr-1">จากปีงบประมาณ</label>
          <b-form-select v-model="selected" :options="options" />
        </div> -->

        <b-form-group class="col-12 col-md-4">
          <div class="align-items-center">
            <label class="mr-1">จากวันที่</label>
            <v-date-picker id="example-datepicker3" label="จากวันที่:" locale="th-TH" v-model="date1"
              @update:from-page="getHeaderInputDate">
              <template v-slot="{ inputValue, inputEvents }">
                <b-form-input :value="formatDate(inputValue, 'date1')" v-on="inputEvents" />
              </template>
              <template #header-title>
                {{ headerInputDate }}
              </template>
            </v-date-picker>
          </div>
        </b-form-group>
        <b-form-group class="col-12 col-md-4">
          <div class="align-items-center">
            <label class="mr-1">ถึงวันที่</label>

            <v-date-picker id="example-datepicker3" label="ถึงวันที่:" locale="th-TH" v-model="date2"
              @update:from-page="getHeaderInputDate">
              <template v-slot="{ inputValue, inputEvents }">
                <b-form-input :value="formatDate(inputValue, 'date2')" v-on="inputEvents" />
              </template>
              <template #header-title>
                {{ headerInputDate }}
              </template>
            </v-date-picker>
          </div>
        </b-form-group>

        <b-form-group class="col-12 col-md-4">
          <label>กลุ่มงาน:</label>

          <v-select v-model="workgroup" :options="workgroups" label="work_gid_work_gname" placeholder="เลือกกลุ่มงาน" />
        </b-form-group>
      </div>
      <div class="row justify-content-between">
        <div class="row col-6 mb-1">
          <div class="ml-1">
            <b-button variant="success" @click="get_table_report">ค้นหา</b-button>
          </div>
          <div class="ml-1">
            <b-button variant="danger" @click="cc_report">ยกเลิก</b-button>
          </div>
          <div class="ml-1">
            <b-overlay :show="load_excel" rounded="sm">
              <b-button variant="outline-primary" @click="exportExcel">Export Excel</b-button>
            </b-overlay>
          </div>
        </div>
        <div class="mr-2">
          <b-overlay :show="load_pdf" rounded="sm">
            <b-button variant="outline-primary" @click="exportpdf">พิมพ์รายงานแยกตามกลุ่มทรัพย์สินกลุ่มงาน PDF</b-button>
          </b-overlay>
        </div>
      </div>
    </div>

    <!-- table -->
    <b-overlay :show="show" rounded="sm">
      <vue-good-table :columns="columns" :rows="rows" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" :pagination-options="{
  enabled: true,
  perPage: pageLength,
}" theme="my-theme" :line-numbers="true">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'fullName'" class="text-nowrap">
            <b-avatar :src="props.row.avatar" class="mx-1" />
            <span>{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'listmenu'">
            <span>
              <b-button variant="outline-warning" :to="{ name: 'inseakuruplun', params: { id: props.row } }">
                <feather-icon icon="EyeIcon" class="mr-50" />
              </b-button>
            </span>
          </span>
          <span v-else-if="props.column.field === 'จำนวน'">
            <div class="text-right">{{ props.row.จำนวน }}</div>
          </span>
          <!-- <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
            <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
          </span> -->
          <span v-else-if="props.column.field === 'status'">
            <div v-if="props.row.status === 'รับเข้า'">
              <b-button variant="info">{{ props.row.status }}</b-button>
            </div>
            <div v-else-if="props.row.status === 'เบิก'">
              <b-button variant="warning">{{ props.row.status }}</b-button>
            </div>
            <div v-else-if="props.row.status === 'ยืม'">
              <b-button variant="danger">{{ props.row.status }}</b-button>
            </div>
            <div v-else-if="props.row.status === 'คืน'">
              <b-button variant="success">{{ props.row.status }}</b-button>
            </div>
          </span>

          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">Showing 1 to</span>
              <b-form-select v-model="pageLength" :options="['5', '10', '15', '25', '50', '100']" class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
              <span class="text-nowrap">of {{ total }} entries</span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="total" :per-page="pageLength" first-number last-number align="right"
                prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import pdfMake from "pdfmake";
import pdfFonts from "../font/custom-fonts";
import _ from "lodash";
import { async } from "q";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },
  data() {
    return {
      date1: new Date(),
      date2: new Date(),
      date: {
        startDate: "",
        endDate: "",
      },
      selected: null,
      year_selected: "",
      options: [{ value: null, text: "เลือกปีงบประมาณ" }],
      equipment_code: "",
      e_number: "",
      serial_number: "",
      e_name: "",
      currentPage: 1,
      pageLength: 100,
      total: "",
      show: false,
      dir: false,
      workgroup: null,
      workgroups: [],
      equipmentGroup: null,
      equipmentGroups: [],
      columns: [
        {
          label: "วันที่-เดือน-ปี",
          field: "วันที่รับเข้า",
        },
        {
          label: "เลขทะเบียน",
          field: "เลขทะเบียน",
        },
        {
          label: "รหัสทรัพย์สิน",
          field: "หมายเลขทรัพย์สิน",
        },

        {
          label: "ชื่อทรัพย์สิน",
          field: "ชื่อทรัพย์สิน",
        },
        {
          label: "รายการ รายละเอียด",
          field: "รายละเอียด",
        },
        {
          label: "หน่วยนับ",
          field: "หน่วยนับ",
        },
        {
          label: "ราคาต่อหน่วย",
          field: "ราคาต่อหน่วย",
          formatFn: this.formatFn,
        },
        {
          label: "จำนวน",
          field: "จำนวน",
        },
        {
          label: "มูลค่ารวม",
          field: "มูลค่ารวม",
          formatFn: this.formatFn,
        },
        {
          label: "อายุการใช้งาน",
          field: "อายุการใช้งาน",
          formatFn: this.formatFn,
        },
        {
          label: "อัตราค่าเสื่อมราคา",
          field: "อัตราค่าเสื่อมราคา",
          formatFn: this.formatFn,
        },
        {
          label: "ค่าเสื่อมประจำปี",
          field: "อัตราค่าเสื่อมราคาต่อปี",
          formatFn: this.formatFn,
        },
        {
          label: "ค่าเสื่อมราคาสะสม",
          field: "ค่าเสื่อมราคาสะสม",
          formatFn: this.formatFn,
        },
        {
          label: "มูลค่าสุทธิ",
          field: "มูลค่าสุทธิ",
          formatFn: this.formatFn,
        },
        {
          label: "ใช้ได้",
          field: "ใช้ได้ใช้ไม่ได้",
        },
        {
          label: "ใช้ไม่ได้",
          field: "ใช้ได้ใช้ไม่ได้",
        },
        {
          label: "ซ่อม",
          field: "ซ่อม",
        },
        {
          label: "จำหน่าย",
          field: "จำหน่าย",
        },
        {
          label: "สถานที่เก่า/ผู้รับผิดชอบ",
          field: "สถานที่เก่า",
        },
        {
          label: "สถานที่ใหม่/ผู้รับผิดชอบ",
          field: "สถานที่ใหม่",
        },
      ],

      excel_row: [],
      rows: [],
      contentData: [],
      searchTerm: "",
      load_pdf: false,
      load_excel: false,
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      new_arr: [],
      init: 0,
      inspector: {},
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  created() {
    const y = new Date().getFullYear();
    // const yy = y + 543;
    const yy = y + 544;
    this.options.push({ value: yy, text: yy });
    for (let index = 1; index < 20; index++) {
      this.options.push({ value: yy - index, text: yy - index });
    }
    this.workgroups_table();
    this.equipment_table();
    // this.checkLogout();
  },
  methods: {
    async checkLogout() {
      console.log("ตรวจสอบออกจากระบบ");
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(" User อยู่ในระบบ");
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
    },
    getHeaderInputDate(events) {
      switch (events.month) {
        case 1:
          this.headerInputDate = "มกราคม " + (events.year + 543);
          break;
        case 2:
          this.headerInputDate = "กุมภาพันธ์ " + (events.year + 543);
          break;
        case 3:
          this.headerInputDate = "มีนาคม " + (events.year + 543);
          break;
        case 4:
          this.headerInputDate = "เมษายน " + (events.year + 543);
          break;
        case 5:
          this.headerInputDate = "พฤษภาคม " + (events.year + 543);
          break;
        case 6:
          this.headerInputDate = "มิถุนายน " + (events.year + 543);
          break;
        case 7:
          this.headerInputDate = "กรกฎาคม " + (events.year + 543);
          break;
        case 8:
          this.headerInputDate = "สิงหาคม " + (events.year + 543);
          break;
        case 9:
          this.headerInputDate = "กันยายน " + (events.year + 543);
          break;
        case 10:
          this.headerInputDate = "ตุลาคม " + (events.year + 543);
          break;
        case 11:
          this.headerInputDate = "พฤศจิกายน " + (events.year + 543);
          break;
        case 12:
          this.headerInputDate = "ธันวาคม " + (events.year + 543);
          break;
        default:
          this.headerInputDate = "ข้อมูลผิดพลาด";
          break;
      }
      //console.log(this.headerInputDate);
    },
    formatDate(date, val) {
      console.log(val);
      let formatDate = "";
      let dete_val = "";
      if (date != null) {
        const _y = date.split("/");
        const _date = parseInt(_y[2]) + 543;
        formatDate = `${_y[0]}/${_y[1]}/${_date}`;
        dete_val = _y[2] + "-" + _y[1] + "-" + _y[0];
        switch (val) {
          case "date1":
            this.date.startDate = dete_val;
            break;
          case "date2":
            this.date.endDate = dete_val;
            break;
        }
      } else {
        formatDate = "-";
      }
      if (formatDate.toString() == "/undefined/NaN") {
        formatDate = "";
      }
      return formatDate;
    },
    async inspectorForReport() {
      const { access_token } = await this.access_token();
      const url = `${API}inspectorForReport?e_number=${this.e_number}`;
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      // this.inspector = res.data.message.data[0];
      // console.log(this.inspector);

      const dataApi = res.data.message.data;

      this.inspector = dataApi.map((res) => {
        return {
          inspector1: res.inspector1 || "" || undefined,
          inspector2: res.inspector2 || "" || undefined,
          inspector3: res.inspector3 || "" || undefined,
          inspector_pos1: res.inspector_pos1 || "" || undefined,
          inspector_pos2: res.inspector_pos2 || "" || undefined,
          inspector_pos3: res.inspector_pos3 || "" || undefined,
        };
      });

      // this.inspector = _.isEmpty() ? res.data.message.data[0] : ''
      // console.log();
      // if (res.data.message.data[0] == undefined) {
      //   this.inspector = undefined;
      // } else {
      //   this.inspector = res.data.message.data[0];
      // }
    },
    async exportpdf() {
      this.load_pdf = true;
      const startDate = `${this.selected - 544}-10-01`;
      const endDate = `${this.selected - 543}-09-30`;

      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url1 = `${API}reportEquipmentCheckSummary?startDate=${this.date.startDate}&endDate=${this.date.endDate}&work_gid=${this.workgroup.work_gid}&_page=1&_limit=100`;
      //const url1 = `${API}reportEquipmentCheckSummary?startDate=${startDate}&endDate=${endDate}&work_gid=${this.workgroup.work_gid}&_page=1&_limit=100&_sort=-1`;
      console.log(url1);
      const data1 = await axios.get(url1, header);

      // console.log(data1.data.message.total);
      const res_total = Math.ceil(Number(data1.data.message.total) / 100);
      const url_link = [];
      for (let i = 1; i <= res_total; i++) {
        url_link.push(
          `${API}reportEquipmentCheckSummary?startDate=${this.date.startDate}&endDate=${this.date.endDate}&work_gid=${this.workgroup.work_gid}&_page=${i}&_limit=100&_sort=-1`
        );
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i], header)).data.message.data];
      }
      console.log(res_data_);
      const res1 = res_data_;
      // console.log(res1);

      const res2 = [];
      const sumin = data1.data.message.sum;

      //api 2
      const url2 = await res1.map((res) => {
        return `${API}propertyRegistration?e_number=${res.หมายเลขทรัพย์สิน}&lifetime=${res.อายุการใช้งาน}&refkey=${res.refkey}`;
      });
      console.log(url2);
      this.e_number = data1.data.message.data[0].หมายเลขทรัพย์สิน;
      this.inspectorForReport();
      for (let i in url2) {
        const data2 = await axios.get(url2[i], header);
        console.log(data2);
        res2.push(data2.data.message.data);
      }

      var dateSelect = "";
      console.log("3333");
      var _checkdate = dayjs(this.date.startDate).isBetween(
        this.date.startDate.split("-")[0] + "-10-01",
        this.date.startDate.split("-")[0] + "-12-31",
        undefined,
        "[]"
      );
      console.log(_checkdate);
      const chk = dayjs(this.date.endDate).isBetween(
        this.date.endDate.split("-")[0] + "-10-01",
        this.date.endDate.split("-")[0] + "-12-31",
        undefined,
        "[]"
      );
      console.log(chk);
      if (_checkdate == true && chk == true) {
        dateSelect = `${Number(this.date.endDate.split("-")[0]) + 1}`;
      } else if (_checkdate == true && chk == false) {
        dateSelect = `${Number(this.date.endDate.split("-")[0])}`;
      } else {
        dateSelect = `${Number(this.date.endDate.split("-")[0])}`;
      }

      // const chk = dayjs(this.date.startDate).isAfter(this.date.startDate.split("-")[0] + "-10-01", "month"); // compares month and year
      // console.log(chk)

      // const chk2 = dayjs(this.date.startDate).isSame(this.date.startDate.split("-")[0] + "-10-01", "month"); // compares month and year
      // console.log(chk2)
      // var dateSelect = "";

      // if (chk == false && chk2 == false) {
      //   dateSelect = `${Number(this.date.endDate.split("-")[0])}`;
      // } else {
      //   dateSelect = `${Number(this.date.endDate.split("-")[0])}`;
      // }
      // const dateSelect = '2022';
      //const dateSelect = `${this.date.endDate.split("-")[0].toString()}`;
      // console.log(dateSelect);
      // const dataFilter = res2.map((res) => {

      //   const [response] = res.filter((res2, index) => dateSelect == res2.admitdate.substring(0, 4) && index !== 0);
      //   return response;
      // });
      const ff = [];
      const dataFilter = res2.map((res, index) => {
        //  const [response] = res.filter((el, index2) => dateSelect.toString() == el.admitdate.substring(0, 4).toString() && index2 !== 0);
        res.forEach((element, index2) => {
          const cc = element.admitdate.substring(0, 4);
          //  console.log(cc)
          //  console.log(dateSelect.toString())
          if (cc == dateSelect.toString() && index2 !== 0) {
            ff.push(element);
          }
        });
        // console.log(response)
        // return response;
      });
      ff.map((res, index) => {
        // console.log('asdasdasdasdasdasdsad1111111')
        // console.log(res)
        // console.log(res1[index]['วันที่รับเข้า'] )
        (res1[index]["วันที่รับเข้า"] = `${parseInt(res.admitdate.split("-")[2])} ${this.month[parseInt(res.admitdate.split("-")[1])]
          } ${Number(res.admitdate.split("-")[0]) + 543}`),
          (res1[index]["ค่าเสื่อมประจำปี"] = res["ค่าเสื่อมราคาประจำปี"]),
          (res1[index]["ค่าเสื่อมราคาสะสม"] = res["ค่าเสื่อมราคาสะสม"]),
          (res1[index]["มูลค่าสุทธิ"] = res["มูลค่าสุทธิ"]),
          (res1[index]["อัตราค่าเสื่อมราคาต่อปี"] = res["อัตราค่าเสื่อมราคาต่อปี"]),
          (res1[index]["อัตราค่าเสื่อมราคาต่อวัน"] = res["อัตราค่าเสื่อมราคาต่อวัน"]);
      });

      const lastData = [];
      let lastData2 = [];
      let g_names = "";
      console.log(res1);
      res1.map((res, index) => {
        console.log(res.g_name, index);
        console.log("dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd");
        const raw = {
          g_name: "",
          e_gid: "",
          ค่าเสื่อมประจำปี: "",
          ค่าเสื่อมราคาสะสม: "",
          จำนวน: "",
          จำหน่าย: "",
          ซ่อม: "",
          มูลค่ารวม: "",
          มูลค่าสุทธิ: "",
          ราคาต่อหน่วย: "",
          ราคาต่อหน่วยรวมvat: "",
          รายละเอียด: "",
          วันที่รับเข้า: "",
          สถานที่เก่า: "",
          สถานที่ใหม่: "",
          หน่วยนับ: "",
          หมายเลขทรัพย์สิน: "",
          อัตราค่าเสื่อมราคา: "",
          อัตราค่าเสื่อมราคาต่อปี: "",
          อัตราค่าเสื่อมราคาต่อวัน: "",
          อายุการใช้งาน: "",
          เลขทะเบียน: "",
          ใช้ได้ใช้ไม่ได้: "",
        };
        if (res.g_name === "" || res.g_name !== g_names) {
          // header
          raw["ชื่อทรัพย์สิน"] = res.g_name;
          lastData.push(raw);
          g_names = res.g_name;
          lastData2.push({ [res.g_name]: [] });
          console.log(lastData2);
        }
        res["มูลค่ารวม"] = res["ราคาต่อหน่วย"];

        res.keys = index;
        (res["ซ่อม"] = res["ซ่อม"] != "" && res["ซ่อม"] != undefined ? "ซ่อม" : ""),
          (res["จำหน่าย"] = res["จำหน่าย2"] != "" && res["จำหน่าย2"] != undefined ? "จำหน่าย" : ""),
          lastData.push(res);
        console.log(lastData);
        console.log(lastData2);
        console.log(res);
        for (let i in lastData2) {
          // console.log(Object.keys(lastData2[i]));
          // console.log(res);

          console.log(lastData2[i][Object.keys(lastData2[i])]);
          if (res.g_name == Object.keys(lastData2[i])) {
            console.log(res.g_name);
            lastData2[i][Object.keys(lastData2[i])].push(res);
          }
        }
      });

      console.log(lastData2);

      var x1 = 0;
      var x2 = 0;
      var x3 = 0;
      var x4 = 0;

      for (let i in lastData2) {
        console.log(lastData2[i][Object.keys(lastData2[i])].length);
        for (let r in lastData2[i][Object.keys(lastData2[i])]) {
          // console.log(lastData2[i][Object.keys(lastData2[i])][r].ค่าเสื่อมประจำปี)
          x1 += Number(lastData2[i][Object.keys(lastData2[i])][r].ค่าเสื่อมประจำปี);
          x2 += Number(lastData2[i][Object.keys(lastData2[i])][r].ค่าเสื่อมราคาสะสม);
          x3 += Number(lastData2[i][Object.keys(lastData2[i])][r].มูลค่าสุทธิ);
          x4 += Number(lastData2[i][Object.keys(lastData2[i])][r].ราคาต่อหน่วย);
        }
        lastData2[i][Object.keys(lastData2[i])].push({
          ค่าเสื่อมประจำปี: x1,
          ค่าเสื่อมราคาสะสม: x2,
          มูลค่าสุทธิ: x3,
          มูลค่ารวม: x4,
          รายละเอียด: "รวมเป็นเงินทั้งสิ้น",
        });
        // lastData2[i][Object.keys(lastData2[i])].push(res);
        x1 = 0;
        x2 = 0;
        x3 = 0;
        x4 = 0;
      }

      // console.log(xxx);
      console.log(lastData2);
      let ooo = 0;
      var namepp = "";
      //this.rows = lastData;
      this.show = false;

      const res = lastData2.map((res, index) => {
        console.log(res)
        return {
          [Object.keys(res)[0]]: res[Object.keys(res)].map((res2, index, arr) => {
            console.log(res2);
            console.log(index);
            console.log(arr.length);
            namepp = res2.g_name;

            // console.log(res2.เลขทะเบียน);
            if (res2.เลขทะเบียน != undefined) {
              ooo = ooo + 1;
            } else {
              ooo = 0;
            }
            let gen_key = "";

            const data_return = [
              { text: `${res2.เลขทะเบียน ? ooo : ""}`, style: "text_center" },
              { text: res2.วันที่รับเข้า, style: "text_left" },
              { text: res2.เลขทะเบียน, style: "text_left" },
              { text: res2.หมายเลขทรัพย์สิน, style: "text_left" },
              {
                text: `${res2.รายละเอียด === "รวมเป็นเงินทั้งสิ้น" ? "รวมเป็นเงินทั้งสิ้น" : res2.รายละเอียด}`,
                style: "text_left",
              },
              { text: res2.หน่วยนับ, style: "text_center" },
              {
                text:
                  Number(res2.ราคาต่อหน่วย)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") === "NaN"
                    ? " "
                    : Number(res2.ราคาต่อหน่วย)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                style: "text_right",
              },
              { text: res2.จำนวน, style: "text_right" },
              {
                text:
                  Number(res2.มูลค่ารวม)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") == 0.0
                    ? " "
                    : Number(res2.มูลค่ารวม)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                style: "text_right",
              },
              {
                text: res2.อายุการใช้งาน,
                style: "text_right",
              },
              {
                text: res2.อัตราค่าเสื่อมราคาต่อปี ? res2.อัตราค่าเสื่อมราคาต่อปี : " ",
                style: "text_right",
              },
              {
                text: res2.ค่าเสื่อมราคาสะสม ? Number(res2.ค่าเสื่อมราคาสะสม).toFixed(2)
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : " ",
                style: "text_right",
              },
              {
                text: Number(res2.ค่าเสื่อมราคาสะสม)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                style: "text_right",
              },
              {
                text: Number(res2.มูลค่าสุทธิ)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                style: "text_right",
              },
              { text: res.ใช้ได้, style: "text_center" },
              { text: res.ใช้ไม่ได้, style: "text_center" },

              {
                text: res2.ซ่อม,
                style: "text_left",
              },
              {
                text: res2.จำหน่าย,
                style: "text_left",
              },
              { text: res2.สถานที่เก่า, style: "text_left" },
              { text: res2.สถานที่ใหม่, style: "text_left" },
            ];
            return data_return;
          }),
        };
      });

      console.log("---------------------------------------------------");
      console.log(res);
      console.log("---------------------------------------------------");

      console.log("---------------------------------------------------");

      const contentData = res.map((res, idx, arr) => {
        // console.log(idx);
        // console.log(arr.length);
        console.log(Object.keys(arr[idx])[0]);
        console.log("landscape");
        return [
          // idx !== arr.length - 1
          //   ? { text: `${Object.keys(arr[idx])[0]}`, pageOrientation: 'landscape' }
          //   : arr.length - 1 === 0
          //   ? { text: `${Object.keys(arr[idx])[0]}`, pageOrientation: 'landscape' }
          //   : {},
          idx == 0 ? { text: `${Object.keys(arr[idx])[0]}`, pageOrientation: "landscape" } : {},
          {
            style: "tableHeader",
            table: {
              headerRows: 3,
              keepWithHeaderRows: 2,
              widths: [
                "auto",
                "auto",
                "auto",
                "auto",
                "*",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
              ],
              body: [
                [
                  {
                    text: "ลำ ดับ",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "วัน-เดือน-ปี",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "เลขทะเบียน",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "รหัสทรัพย์สิน",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "รายการ",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "หน่วย นับ",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "ราคา/หน่วย",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "จำ นวน",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "มูลค่ารวม",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "อายุ ใช้งาน",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "อัตตรา ค่า เสื่อม",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "ค่าเสื่อม ประจำปี",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "ค่าเสื่อม ราคาสะสม",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "มูลค่า สุทธิ",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },

                  {
                    text: "สภาพ",
                    style: "tableHeader",
                    alignment: "center",
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},

                  {
                    text: "สถานที่/ผู้รับผิดชอบ (เดิม)",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "สถานที่/ผู้รับผิดชอบ (ใหม่)",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                ],
                [
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "ใช้ได้",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "ใช้ ไม่ได้",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "ซ่อม",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "จำหน่าย",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                ],
                [
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                ],
                ...res[Object.keys(res)[0]],
              ],
            },
          },
          idx !== arr.length - 1
            ? {
              text: `${Object.keys(arr[idx + 1])[0]}`,
              pageOrientation: "landscape",
              pageBreak: "before",
            }
            : {},
        ];
      });
      this.contentData = contentData;

      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Medium.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageMargins: [10, 70, 10, 60],
        pageSize: "A4",
        pageOrientation: "landscape",
        info: {
          title: "รายงานผลการตรวจสอบทรัพย์สินเเยกตามกลุ่มงาน",
        },
        header: [
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            alignment: "center",
            margin: [0, 15, 0, 0],
            style: "header",
          },
          {
            text: `รายงานผลการตรวจสอบทรัพย์สิน ประจำปีงบประมาณ ${Number(dateSelect) + 543}`,
            alignment: "center",
            style: "header",
          },
          {
            text: `การรับ-จ่าย ทรัพย์สิน ตั้งเเต่วันที่ ${parseInt(this.date.startDate.split("-")[2])} ${this.month[parseInt(this.date.startDate.split("-")[1])]
              } ${Number(this.date.startDate.split("-")[0]) + 543} - ${parseInt(this.date.endDate.split("-")[2])} ${this.month[parseInt(this.date.endDate.split("-")[1])]
              } ${Number(this.date.endDate.split("-")[0]) + 543}`,
            style: "header",
          },

          {
            text: `กลุ่มงาน ${this.workgroup.work_gid} - ${this.workgroup.work_gname}`,
            style: "header",
          },
        ],
        footer: {
          columns: [
            {
              text: `(ลงชื่อ)...................................................................(ประธานกรรมการ)
              (${this.inspector[0]?.inspector1 === undefined
                  ? "..................................................................."
                  : this.inspector[0]?.inspector1
                })
              ตำแหน่ง ${this.inspector[0]?.inspector_pos1 === undefined
                  ? "..................................................................."
                  : this.inspector[0]?.inspector_pos1
                }
              `,
              margin: [0, 20, 0, 0],
              alignment: "center",
              style: "text",
            },
            {
              text: `(ลงชื่อ)...................................................................(กรรมการ)
              (${this.inspector[0]?.inspector2 === undefined
                  ? "..................................................................."
                  : this.inspector[0]?.inspector2
                })
              ตำแหน่ง ${this.inspector[0]?.inspector_pos2 === undefined
                  ? "..................................................................."
                  : this.inspector[0]?.inspector_pos2
                }`,
              margin: [0, 20, 0, 0],
              alignment: "center",
              style: "text",
            },
            {
              text: `(ลงชื่อ)...................................................................(กรรมการเเละเลขานุการ)
              (${this.inspector[0]?.inspector3 === undefined
                  ? "..................................................................."
                  : this.inspector[0]?.inspector3
                })
              ตำแหน่ง ${this.inspector[0]?.inspector_pos3 === undefined
                  ? "..................................................................."
                  : this.inspector[0]?.inspector_pos3
                }`,
              margin: [0, 20, 0, 0],
              alignment: "center",
              style: "text",
            },
          ],
        },

        content: this.contentData,
        defaultStyle: {
          font: "Kanit",
        },
        styles: {
          tableExample: {
            margin: [0, 1, 0, 1],
          },
          tableHeader: {
            bold: true,
            fontSize: 5,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
            alignment: "center",
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 0, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            alignment: "center",
            bold: false,
            fontSize: 5,
          },
          text_left: {
            alignment: "left",
            bold: false,
            fontSize: 5,
          },
          text_right: {
            bold: false,
            alignment: "right",
            fontSize: 5,
          },
          text: {
            fontSize: 7,
          },
        },
      };
      this.load_pdf = false;
      pdfMake
        .createPdf(docDefinition)
        .download(`รายงานผลการตรวจสอบวัสดุเเยกตามกลุ่มงาน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`);
      //pdfMake.createPdf(docDefinition).open();
    },
    cc_report() {
      this.rows = [];
      this.date.startDate = "";
      this.date.endDate = "";
      this.workgroup = null;
    },
    async exportExcel() {
      this.load_excel = true;
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };

      // const startDate = `${this.selected - 544}-10-01`;
      // const endDate = `${this.selected - 543}-09-30`;
      // this.date.startDate = startDate;
      // this.date.endDate = endDate;
      console.log(this.date.startDate);
      console.log(this.date.endDate);

      const url__ = `${API}calculateAnnualDepreciation?startDate=${this.date.startDate}&endDate=${this.date.endDate}&work_gid=${this.workgroup.work_gid}&_page=${this.currentPage}&_limit=${this.pageLength}`;
      console.log(url__);
      const data__ = await axios.get(url__, header);
      console.log(data__);

      const res_total = Math.ceil(Number(data__.data.message.total) / 100);
      console.log(res_total);
      const url_link = [];
      for (let i = 1; i <= res_total; i++) {
        url_link.push(
          `${API}calculateAnnualDepreciation?startDate=${this.date.startDate}&endDate=${this.date.endDate}&work_gid=${this.workgroup.work_gid}&_page=${i}&_limit=${this.pageLength}`
        );
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i], header)).data.message.dataLast];
      }
      console.log(res_data_);

      // const { dataLast, sum, total } = data__.data.message;
      let res__ = [];
      console.log(res_data_);

      res__.push(...res_data_, data__.data.message.sum);
      console.log(res__);
      var dateSelect = "";
      var _checkdate = dayjs(this.date.startDate).isBetween(
        this.date.startDate.split("-")[0] + "-10-01",
        this.date.startDate.split("-")[0] + "-12-31",
        undefined,
        "[]"
      );
      console.log(_checkdate);
      const chk = dayjs(this.date.endDate).isBetween(
        this.date.endDate.split("-")[0] + "-10-01",
        this.date.endDate.split("-")[0] + "-12-31",
        undefined,
        "[]"
      );
      console.log(chk);
      if (_checkdate == true && chk == true) {
        dateSelect = `${Number(this.date.endDate.split("-")[0]) + 1}`;
      } else if (_checkdate == true && chk == false) {
        dateSelect = `${Number(this.date.endDate.split("-")[0])}`;
      } else {
        dateSelect = `${Number(this.date.endDate.split("-")[0])}`;
      }

      let rows_sa = res__.map((el, index, arr) => {
        // console.log(arr.length - 1);
        // console.log(index);
        console.log(el);
        if (arr.length - 1 === index) {
        } else {

          el["วันที่รับเข้า"] = `${parseInt(el["วันที่รับเข้า"].split("-")[2])} ${this.month[parseInt(el["วันที่รับเข้า"].split("-")[1])]
            } ${Number(el["วันที่รับเข้า"].split("-")[0]) + 543}`;
        }
        return el;
      },


      );
      rows_sa.map((el) => {
        return {
          ...el,
          ใช้ได้: el.ใช้ได้ใช้ไม่ได้ === "ใช้ได้" ? "ใช้ได้" : "",
          ใช้ไม่ได้: el.ใช้ได้ใช้ไม่ได้ === "ใช้ไม่ได้" ? "ใช้ไม่ได้" : "",
        }
      })
      saveExcel({
        data: rows_sa,
        fileName: `รายงานผลการตรวจสอบวัสดุเเยกตามกลุ่มงาน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`,
        columns: [
          {
            title: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: ` รายงานผลการตรวจสอบทรัพย์สิน ประจำปีงบประมาณ ${Number(dateSelect) + 543}`,
                headerCellOptions: { textAlign: "center" },
                children: [
                  {
                    title: `การรับ-จ่าย ทรัพย์สิน ตั้งเเต่วันที่ ${parseInt(this.date.startDate.split("-")[2])} ${this.month[parseInt(this.date.startDate.split("-")[1])]
                      } ${Number(this.date.startDate.split("-")[0]) + 543} - ${parseInt(
                        this.date.endDate.split("-")[2]
                      )} ${this.month[parseInt(this.date.endDate.split("-")[1])]} ${Number(this.date.endDate.split("-")[0]) + 543
                      }`,
                    headerCellOptions: { textAlign: "center" },
                    children: [
                      {
                        title: ` กลุ่มงาน ${this.workgroup.work_gname}`,

                        headerCellOptions: { textAlign: "center" },
                        children: [
                          {
                            title: "วันที่-เดือน-ปี",
                            field: "วันที่รับเข้า",
                          },
                          {
                            title: "เลขทะเบียน",
                            field: "เลขทะเบียน",
                          },
                          {
                            title: "รหัสทรัพย์สิน",
                            field: "หมายเลขทรัพย์สิน",
                          },

                          {
                            title: "รายการ",
                            field: "รายละเอียด",
                          },

                          {
                            title: "หน่วยนับ",
                            field: "หน่วยนับ",
                          },
                          {
                            title: "ราคาต่อหน่วย",
                            field: "ราคาต่อหน่วย",
                          },
                          {
                            title: "จำนวน",
                            field: "จำนวน",
                          },
                          {
                            title: "มูลค่ารวม",
                            field: "มูลค่ารวม",
                          },
                          {
                            title: "อายุการใช้งาน",
                            field: "อายุการใช้งาน",
                          },
                          {
                            title: "อัตราค่าเสื่อมราคา",
                            field: "อัตราค่าเสื่อมราคา",
                          },
                          {
                            title: "ค่าเสื่อมประจำปี",
                            field: "ค่าเสื่อมราคาประจำปี",
                          },
                          {
                            title: "ค่าเสื่อมราคาสะสม",
                            field: "ค่าเสื่อมราคาสะสม",
                          },
                          {
                            title: "มูลค่าสุทธิ",
                            field: "มูลค่าสุทธิ",
                          },
                          {
                            title: "สภาพ",
                            headerCellOptions: { textAlign: "center" },
                            children: [
                              {
                                title: "ใช้ได้",
                                field: "ใช้ได้",
                              },
                              {
                                title: "ใช้ไม่ได้",
                                field: "ใช้ไม่ได้",
                              },
                              {
                                title: "ซ่อม",
                                field: "ซ่อม",
                              },
                              {
                                title: "จำหน่าย",
                                field: "จำหน่าย",
                              },
                            ],
                          },
                          {
                            title: "สถานที่/เจ้าหน้าที่(เดิม)",
                            field: "สถานที่เก่า",
                          },
                          {
                            title: "สถานที่/เจ้าหน้าที่(ใหม่)",
                            field: "สถานที่ใหม่",
                          },
                        ],
                      },
                      {},
                    ],
                  },
                  {},
                ],
              },
              {},
            ],
          },
        ],
      });
      this.load_excel = false;
    },
    formatFn: function (value) {
      // console.log(value)
      if (value == "") {
        return Number(value)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") == 0
          ? ""
          : Number(value)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return Number(value)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") == 0
          ? ""
          : Number(value)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    async get_table_report() {
      if (this.workgroup === null) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `กรุณาเลือกกลุ่มงาน`,
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      // if (this.selected === null) {
      //   Swal.fire({
      //     position: "center",
      //     icon: "error",
      //     title: `กรุณาเลือกปีงบประมาณ`,
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      //   return;
      // }
      this.show = true;

      const startDate = `${this.selected - 544}-10-01`;
      const endDate = `${this.selected - 543}-09-30`;
      console.log(this.date.startDate);
      console.log(this.date.endDate);

      //console.log(chk);
      // if (chk == false) {
      //   this.date.endDate = `${Number(this.date.endDate.split("-")[0]) - 1}-${this.date.endDate.split("-")[1]}-${
      //     this.date.endDate.split("-")[2]
      //   }`;
      // } else {
      //   this.date.endDate = `${Number(this.date.endDate.split("-")[0]) + 1}-${this.date.endDate.split("-")[1]}-${
      //     this.date.endDate.split("-")[2]
      //   }`;
      // }
      console.log(this.date.endDate);
      // ถ้าาเลือก หลัง 30 กันยายน ปี 65 จะเป็น ปีงบ ประมาณ 66 /////  01 พย 65 - 30 พย 65

      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url1 = `${API}reportEquipmentCheckSummary?startDate=${this.date.startDate}&endDate=${this.date.endDate}&work_gid=${this.workgroup.work_gid}&_page=1&_limit=100`;
      //const url1 = `${API}reportEquipmentCheckSummary?startDate=${startDate}&endDate=${endDate}&work_gid=${this.workgroup.work_gid}&_page=1&_limit=100&_sort=-1`;
      console.log(url1);
      const data1 = await axios.get(url1, header);

      console.log(data1.data.message);
      if (data1.data.message.data.length == 0) {
        this.rows = [];
        this.show = false;
        return;
      }
      const res_total = Math.ceil(Number(data1.data.message.total) / 100);
      const url_link = [];
      for (let i = 1; i <= res_total; i++) {
        url_link.push(
          //`${API}reportEquipmentCheckSummary?startDate=${startDate}&endDate=${endDate}&work_gid=${this.workgroup.work_gid}&_page=${i}&_limit=100&_sort=-1`
          // `${API}reportEquipmentCheckSummary?startDate=${this.date.startDate}&endDate=${this.date.endDate}&work_gid=${this.workgroup.work_gid}&_page=${i}&_limit=100`
          `${API}reportEquipmentCheckSummary?startDate=${this.date.startDate}&endDate=${this.date.endDate}&work_gid=${this.workgroup.work_gid}&_page=${i}&_limit=100`

        );
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i], header)).data.message.data];
      }
      //console.log(res_data_);
      const res1 = res_data_;
      console.log(res1);

      const res2 = [];
      const sumin = data1.data.message.sum;
      this.total = data1.data.message.total;
      //api 2
      const url2 = await res1.map((res) => {
        return `${API}propertyRegistration?e_number=${res.หมายเลขทรัพย์สิน}&lifetime=${res.อายุการใช้งาน}&refkey=${res.refkey}`;
      });
      console.log(url2);
      this.e_number = data1.data.message.data[0].หมายเลขทรัพย์สิน;
      this.inspectorForReport();
      for (let i in url2) {
        const data2 = await axios.get(url2[i], header);
        //console.log(data2);

        res2.push(data2.data.message.data);
      }
      console.log(res1);
      console.log(res2);
      // const dateSelect = '2023';
      // console.log(this.date.startDate.split('-')[0])
      // if(){

      // }
      console.log(this.date.startDate);
      console.log(this.date.endDate);
      if (this.date.startDate.split("-")[0]) {
      }

      console.log("11111");
      //  const isBetween = window.dayjs_plugin_isBetween;
      // console.log('2222')
      // dayjs.extend(isBetween);

      var dateSelect = "";
      console.log("3333");
      var _checkdate = dayjs(this.date.startDate).isBetween(
        this.date.startDate.split("-")[0] + "-10-01",
        this.date.startDate.split("-")[0] + "-12-31",
        undefined,
        "[]"
      );
      console.log(_checkdate);
      const chk = dayjs(this.date.endDate).isBetween(
        this.date.endDate.split("-")[0] + "-10-01",
        this.date.endDate.split("-")[0] + "-12-31",
        undefined,
        "[]"
      );
      console.log(chk);
      if (_checkdate == true && chk == true) {
        dateSelect = `${Number(this.date.endDate.split("-")[0]) + 1}`;
      } else if (_checkdate == true && chk == false) {
        dateSelect = `${Number(this.date.endDate.split("-")[0])}`;
      } else {
        dateSelect = `${Number(this.date.endDate.split("-")[0])}`;
      }
      // const chk = dayjs(this.date.startDate).isAfter(this.date.startDate.split("-")[0] + "-10-01", "month"); // compares month and year
      // console.log(chk)

      // const chk2 = dayjs(this.date.startDate).isSame(this.date.startDate.split("-")[0] + "-09-30", "month"); // compares month and year
      // console.log(chk2)
      //

      // if (chk == false && chk2 == false) {
      //   dateSelect = `${Number(this.date.endDate.split("-")[0])}`;
      //   console.log('1')
      // } else {
      //   dateSelect = `${Number(this.date.endDate.split("-")[0])}`;
      //   console.log('+1')
      // } if (_checkdate == true) {
      //   dateSelect = `${Number(this.date.endDate.split("-")[0]) + 1}`;

      // }
      // const chk = dayjs(this.date.startDate).isAfter(this.date.startDate.split("-")[0] + "-10-01", "month"); // compares month and year
      // console.log(chk)
      // var dateSelect = "";
      // if (chk == false) {
      //   dateSelect = `${Number(this.date.endDate.split("-")[0])}`;
      // } else {
      //   dateSelect = `${Number(this.date.endDate.split("-")[0]) + 1}`;
      // }
      //const dateSelect = `${this.date.endDate.split("-")[0].toString()}`;
      console.log(dateSelect);
      console.log(res2);
      const ff = [];
      const dataFilter = res2.map((res, index) => {
        //  const [response] = res.filter((el, index2) => dateSelect.toString() == el.admitdate.substring(0, 4).toString() && index2 !== 0);
        res.forEach((element, index2) => {
          const cc = element.admitdate.substring(0, 4);
          //  console.log(cc)
          //  console.log(dateSelect.toString())
          if (cc == dateSelect.toString() && index2 !== 0) {
            ff.push(element);
          }
        });
        // console.log(response)
        // return response;
      });
      console.log(ff);
      // const dataFilter = res2.map((res, index) => {
      //   const [response] = res.filter((el, index2) => dateSelect == el.admitdate.substring(0, 4) && index2 !== 0);
      //   return response;
      // });
      // console.log(dataFilter);
      console.log(res1);

      //console.log(res1);
      // if(dataFilter [0] === undefined){
      //   this.rows = []
      //   this.show = false;

      //   return
      // }
      ff.map((res, index) => {

        (res1[index]["วันที่รับเข้า"] = `${parseInt(res.admitdate.split("-")[2])} ${this.month[parseInt(res.admitdate.split("-")[1])]
          } ${Number(res.admitdate.split("-")[0]) + 543}`),
          (res1[index]["ค่าเสื่อมประจำปี"] = res["ค่าเสื่อมราคาประจำปี"]),
          (res1[index]["ค่าเสื่อมราคาสะสม"] = res["ค่าเสื่อมราคาสะสม"]),
          (res1[index]["มูลค่าสุทธิ"] = res["มูลค่าสุทธิ"]),
          (res1[index]["อัตราค่าเสื่อมราคาต่อปี"] = res["ค่าเสื่อมราคาประจำปี"]),
          (res1[index]["อัตราค่าเสื่อมราคาต่อวัน"] = res["อัตราค่าเสื่อมราคาต่อวัน"]);
      });

      const lastData = [];
      let lastData2 = [];
      let g_names = "";
      //console.log(res1);
      res1.map((res, index) => {
        //console.log(res.g_name, index);
        //console.log("dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd");
        const raw = {
          g_name: "",
          e_gid: "",
          ค่าเสื่อมประจำปี: "",
          ค่าเสื่อมราคาสะสม: "",
          จำนวน: "",
          จำหน่าย: "",
          ซ่อม: "",
          มูลค่ารวม: "",
          มูลค่าสุทธิ: "",
          ราคาต่อหน่วย: "",
          ราคาต่อหน่วยรวมvat: "",
          รายละเอียด: "",
          วันที่รับเข้า: "",
          สถานที่เก่า: "",
          สถานที่ใหม่: "",
          หน่วยนับ: "",
          หมายเลขทรัพย์สิน: "",
          อัตราค่าเสื่อมราคา: "",
          อัตราค่าเสื่อมราคาต่อปี: "",
          อัตราค่าเสื่อมราคาต่อวัน: "",
          อายุการใช้งาน: "",
          เลขทะเบียน: "",
          ใช้ได้ใช้ไม่ได้: "",
        };
        if (res.g_name === "" || res.g_name !== g_names) {
          // header
          raw["ชื่อทรัพย์สิน"] = res.g_name;
          lastData.push(raw);
          g_names = res.g_name;
          lastData2.push({ [res.g_name]: [] });
          //console.log(lastData2);
        }
        res["มูลค่ารวม"] = res["ราคาต่อหน่วย"];

        res.keys = index;
        (res["ซ่อม"] = res["ซ่อม"] != "" && res["ซ่อม"] != undefined ? "ซ่อม" : ""),
          (res["จำหน่าย"] = res["จำหน่าย2"] != "" && res["จำหน่าย2"] != undefined ? "จำหน่าย" : ""),
          lastData.push(res);
        //console.log(lastData);
        // console.log(lastData2);
        // console.log(res);
        for (let i in lastData2) {
          //console.log(Object.keys(lastData2[i]));
          //console.log(res);

          //console.log(lastData2[i][Object.keys(lastData2[i])]);
          if (res.g_name == Object.keys(lastData2[i])) {
            //console.log(res.g_name);
            lastData2[i][Object.keys(lastData2[i])].push(res);
          }
        }
      });

      console.log(lastData);

      var x1 = 0;
      var x2 = 0;
      var x3 = 0;
      var x4 = 0;

      for (let i in lastData2) {
        //console.log(lastData2[i][Object.keys(lastData2[i])].length);
        for (let r in lastData2[i][Object.keys(lastData2[i])]) {
          // console.log(lastData2[i][Object.keys(lastData2[i])][r].ค่าเสื่อมประจำปี)
          x1 += Number(lastData2[i][Object.keys(lastData2[i])][r].ค่าเสื่อมประจำปี);
          x2 += Number(lastData2[i][Object.keys(lastData2[i])][r].ค่าเสื่อมราคาสะสม);
          x3 += Number(lastData2[i][Object.keys(lastData2[i])][r].มูลค่าสุทธิ);
          x4 += Number(lastData2[i][Object.keys(lastData2[i])][r].ราคาต่อหน่วย);
        }
        lastData2[i][Object.keys(lastData2[i])].push({
          ค่าเสื่อมประจำปี: x1,
          ค่าเสื่อมราคาสะสม: x2,
          มูลค่าสุทธิ: x3,
          มูลค่ารวม: x4,
          ชื่อทรัพย์สิน: "รวมเป็นเงินทั้งสิ้น",
        });
        // lastData2[i][Object.keys(lastData2[i])].push(res);
        x1 = 0;
        x2 = 0;
        x3 = 0;
        x4 = 0;
      }

      // console.log(xxx);
      //console.log(lastData2);
      let ooo = 0;
      var namepp = "";
      console.log(lastData)
      this.rows = lastData;
      this.show = false;
    },
    pageChanged(event) {
      this.currentPage = event.currentPage;
      this.get_table_report();
    },
    perPageChanged(event) {
      this.pageLength = event.currentPerPage;
      this.get_table_report();
    },
    async equipment_table() {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}equipmentGroups?_page=1&_limit=100`;
      const res = await axios.get(url, header);
      this.equipmentGroups = res.data.message.data.map((element) => {
        return {
          ...element,
          e_gid_e_gname: `${element.e_gid} - ${element.e_gname}`,
        };
      });
    },
    async workgroups_table() {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}workgroups?_page=1&_limit=100&_sort=-1`;
      const res = await axios.get(url, header);
      this.workgroups = res.data.message.data.map((element) => {
        return {
          ...element,
          work_gid_work_gname: `${element.work_gid} - ${element.work_gname}`,
        };
      });
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
